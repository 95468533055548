// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

const urlParams = new URLSearchParams(window.location.search);
const jsDebug = urlParams.get('jsDebug');
const consoleP = document.getElementById('console');

if (jsDebug === '') {
	document.body.classList.add("jsdebug")
	window.console.log = (txt) => {
		const p = document.createElement('p');
		p.innerText = typeof txt === 'string' ? txt : JSON.stringify(txt)
		consoleP.appendChild(p);
	}
	window.console.error = window.console.log;
	window.console.warning = window.console.log;
	window.onerror = (event, souce, lineno, colon, error) => console.log(error)
}
